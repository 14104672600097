/* oml this is quite the mess */

html, body, .appRoot {
  height: 100vh;
  margin: 0;
  padding: 0;
}

* {
  border: none;
}

body {
  background: rgb(32, 38, 51);
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
}

button, input, select, label:has(> img) {
  font-weight: bolder;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: none;
  color: white;
  border-radius: 0;
  margin: 3px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

input {
  font-family: monospace;
}

button:hover:enabled, input:hover:enabled, label:has(> img):has(+ input:enabled):hover {
  outline: 2px solid white;
  cursor: pointer;
}

input::placeholder {
  color: black;
  opacity: 0.7;
}

button > img, label > img {
  margin: 0;
  padding: 0;
  background: none;
  image-rendering: auto;
}

button:has(> img), label:has(> img) {
  display: inline-flex;
  padding: 0;
}

label:has(+ input:disabled) > img {
  filter: brightness(0.4);
}

label:has(+ input:checked) > img {
  /* https://codepen.io/sosuke/pen/Pjoqqp for #4fc3ff */
  /* Not a permanent solution */
  filter: brightness(0) saturate(100%) invert(74%) sepia(10%) saturate(7451%) hue-rotate(172deg) brightness(101%) contrast(101%);
}

.appRoot {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 8px;
}

.appRoot > * {
  margin: 0;
}

.SkinManager {
  display: flex;
  flex: 1;
  min-height: 0;
  padding: 0 6px 6px;
  gap: 8px;
}

.MenuBar {
  width: 100%;
  height: 30px;
  background: rgb(103, 88, 146);
  padding: 0;
  outline: 2px solid black;
}

.hidden {
  display: none;
}

.MenuBar button, .MenuBar span, .MenuBar span p {
  background: none;
  height: 30px;
  margin: 0;
  padding: 0 12px;
  outline: none;
}

.MenuBar span {
  display: flex;
  flex-flow: nowrap;
  margin: 0;
  padding: 0;
}

.MenuBar span button {
  flex: 1;
}

.MenuBar span p {
  text-align: center;
  padding: 3px 0;
  aspect-ratio: 1 / 1;
  user-select: none;
}

.MenuBar button:hover:enabled {
  background: rgb(66, 54, 99);
  outline: none;
}

.MenuBar > div {
  position: absolute;
  top: 32px;
}

.MenuBar > div > div {
  position: absolute;
  z-index: 100;
  background: rgb(103, 88, 146);
  display: flex;
  flex-direction: column;
  outline: 2px solid black;
}

.MenuBar button {
  white-space: nowrap;
  text-align: left;
}

.Preview span {
  flex-flow: nowrap;
  gap: 6px;
}

.Preview span button {
  flex: 1;
  margin: 0 0 6px;
}

.Preview img {
  margin-bottom: -4px;
}

img {
  width: 48px;
  height: 48px;
  image-rendering: pixelated;
  background-image: url("assets/checkerboard.png");
  background-size: 12px;
}

span {
  display: flex;
  flex-flow: wrap;
}

hr {
  outline: 1px solid black;
  width: calc(100% - 16px);
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: rgb(67, 70, 100);
  outline: 2px solid black;
  padding: 5px;
}

.container p, .container label {
  margin: 3px;
  overflow: hidden;
}

.container span {
  width: 100%;
}

.paperdoll {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}

.paperdoll-settings {
  position: absolute;
  max-width: calc(100% - 10px);
  height: calc(100% - 10px);
  justify-content: space-evenly;
  pointer-events: none;
  display: flex;
}

.paperdoll-settings input, .paperdoll-settings button, .paperdoll-settings label, .paperdoll-settings select, .paperdoll-settings option {
  pointer-events: all;
}

.paperdoll-settings button > img, .paperdoll-settings label > img {
  width: 32px;
  height: 32px;

}

.paperdoll-settings button:has(> img), .paperdoll-settings label:has(> img) {
  display: inline-flex;
  padding: 4px;
}

.paperdoll-settings span {
  width: fit-content;
}

.paperdoll-canvas-container {
  display: block;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.paperdoll-canvas {
  background-color: rgba(0, 0, 0, 0.4);
  image-rendering: pixelated;
}

.layereditor-canvas {
  height: 512px;
  width: 512px;
  background-size: 512px, 16px;
  image-rendering: pixelated;
}

.LayerManager {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.layer-manager {
  flex: 1;
  flex-direction: column-reverse;
  min-width: 325px;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.3);
  padding: 0;
}

.layer-manager:first-child {
  margin-top: auto;
}

.manager-layer {
  flex-direction: column;
  padding: 3px;
  padding-top: 0;
  margin-top: 2px;
}

.manager-layer-buttons {
  display: flex;
  flex-direction: column;
}

.manager-layer-buttons > button, .manager-layer-colors > input {
  width: 20px;
  height: 20px;
  margin: 2px;
}

.manager-layer-colors {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.manager-layer > span {
  height: 50px;
}

span.layerTitle {
  height: auto;
}

span.layerTitle input {
  margin-right: 6px;
}

.layer-adder {
  height: 95vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.layer-adder-content {
  display: flex;
  flex-direction: column;
  width: 325px;
}

.settingsList span {
  justify-content: space-between;
}

.asset-layer-manager {
  flex-direction: column-reverse;
  background: rgba(0, 0, 0, 0.3);
}

h3 {
  width: 100%;
  text-align: center;
}

.highlighted {
  outline: 2px solid white;
}

.model-features {
  width: 325px;
  overflow-y: auto;
}

.color-picker-parent {
  display: flex;
  flex-direction: column;
  width: min-content;
  align-items: center;
}

.color-label {
  width: 18px;
  height: 18px;
  outline: 2px solid black;
  image-rendering: pixelated;
}

.color-picker {
  position: relative;
  display: none;
  height: 0;
  width: 0;
}

.color-picker > div {
  align-items: center;
  position: absolute;
  z-index: 1000;
  left: -100px;
  width: 200px;
  padding: 10px 0 10px;
}

.color-picker-top > div {
  top: 10px;
}

.color-picker-bottom > div {
  bottom: 35px;
}

.color-picker > div::after {
  content: "";
  position: absolute;
  border-style: solid;
  border-color: black transparent;
  display: block;
  width: 0;
  left: 90px;
}

.color-picker-top > div::after {
  border-width: 0 10px 10px;
  top: -10px;
}

.color-picker-bottom > div::after {
  border-width: 10px 10px 0;
  bottom: -10px;
}

.color-picker > div > input {
  width: 80%;
  height: 12px;
  margin: 10px 0 10px;
  appearance: none;
  outline: 2px solid black;
  image-rendering: pixelated;
}

.color-picker > div > span {
  flex-flow: nowrap;
  width: 80%;
  margin: 10px 0 10px;
  gap: 6px;
}

.color-picker > div > span > input {
  min-width: 0;
  flex: 1;
  margin: 0;
}

.color-picker > div > input::-webkit-slider-thumb, .color-picker > div > input::-moz-range-thumb {
  appearance: none;
  width: 6px;
  height: 14px;
  outline: none;
  border: 2px solid black;
  border-radius: 0;
  background: none;
  cursor: pointer;
}

.color-picker > div > input:hover::-webkit-slider-thumb, .color-picker > div > input:hover::-moz-range-thumb {
  border: 2px solid white;
}

.dropdown > div > hr:first-of-type {
  margin-top: 10px;
  margin-bottom: 10px;
}

.dropdown-bar {
  user-select: none;
  cursor: pointer;
  justify-content: space-around;
  flex-wrap: nowrap;
}

.dropdown-bar:hover {
  outline: 2px solid white;
}

.dropdown-bar > * {
  flex-grow: 1;
}

.dropdown-bar > span {
  justify-content: end;
  width: min-content;
}

.dropdown-bar > span > button:hover {
  outline: none;
}

.draggable {
  position: absolute;
  z-index: 80;
}

.draggable > span {
  user-select: none;
  cursor: grab;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-bottom: 4px;
}

.draggable > span:active {
  cursor: grabbing;
}

.draggable > span > p {
  flex: 1;
  margin: 0;
  padding: 0;
}

.draggable > span > button {
  margin: 0;
}

.grid-select {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.grid-select > * {
  margin: 3px;
  padding: 0;
  height: auto;
  cursor: pointer;
  background: none;
  background-color: rgba(0, 0, 0, 0.3);
}

.grid-select > *:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.grid-select > div {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.grid-select > div > img {
  position: absolute;
  padding: 0;
  height: 100%;
  width: auto;
  image-rendering: auto;
  background: none;
}

*:disabled {
  cursor: default;
  color: black;
  font-weight: normal;
}

label {
  user-select: none;
  cursor: pointer;
  margin: 10px 5px 0 10px;
}

label:has(+ input:disabled) {
  cursor: default;
}

.left {
  position: absolute;
  left: 0;
}

.right {
  position: absolute;
  right: 0;
}

.top {
  position: absolute;
  top: 0;
}

.bottom {
  position: absolute;
  bottom: 0;
}

.vertical {
  display: flex;
  justify-content: center;
}